import { Trophy } from '@styled-icons/remix-line';
import { Trophy as TrophyFill } from '@styled-icons/remix-fill';

import { Icon, IconProps } from './Icon';

export function TrophyIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <Trophy />
    </Icon>
  );
}

export function TrophyFullIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <TrophyFill />
    </Icon>
  );
}
